
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import ColorPicker from "@/components/Input/ColorPicker.vue";
import { Notification } from "@/models/notification.interface";
import { Functionality } from "@/models/functionality.interface";
import { StyleCheck } from "@/mixins/style-check";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, ColorPicker, DotsMenu },
})
export default class Functionalities extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  loader = false;
  loading = false;
  updateDialog = false;
  updateOnlyLogoDialog = false;
  onlyLogoStatus = false;
  kycStatus = false;
  functionality: Functionality = {};

  private openUpdateDialog(functionality: Functionality) {
    for (let index = 0; index < this.functionalities.length; index++) {
      if (
        this.functionalities[index].name == this.$constants.FUNCTIONALITY.KYC
      ) {
        this.kycStatus =
          this.functionalities[index].status?.name ==
          this.$constants.STATUS.ACTIVE
            ? true
            : false;
      }
    }
    this.functionality = functionality;
    this.updateDialog = true;
  }

  private openOnlyLogoDialog(functionality: Functionality) {
    for (let index = 0; index < this.functionalities.length; index++) {
      if (
        this.functionalities[index].name ==
        this.$constants.FUNCTIONALITY.ONLY_LOGO
      ) {
        this.onlyLogoStatus =
          this.functionalities[index].status?.name ==
          this.$constants.STATUS.ACTIVE
            ? true
            : false;
      }
    }
    this.functionality = functionality;
    this.updateOnlyLogoDialog = true;
  }

  private async updateKYC() {
    this.loading = true;
    await this.$store
      .dispatch("functionalities/setFunctionalityStatus", {
        status: this.kycStatus
          ? this.$constants.STATUS.ACTIVE
          : this.$constants.STATUS.INACTIVE,
        name: this.functionality.name,
        id: this.functionality.id,
      })
      .then(async () => {
        await this.$store
          .dispatch("functionalities/getFunctionalities")
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Functionalities.fetchError.get"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        const Success: Notification = {
          message: this.$tc("Functionalities.success"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        this.updateDialog = false;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Functionalities.fetchError.kycStatus"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async updateOnlyLogo() {
    this.loading = true;
    await this.$store
      .dispatch("functionalities/setFunctionalityStatus", {
        status: this.onlyLogoStatus
          ? this.$constants.STATUS.ACTIVE
          : this.$constants.STATUS.INACTIVE,
        name: this.functionality.name,
        id: this.functionality.id,
      })
      .then(async () => {
        await this.$store
          .dispatch("functionalities/getFunctionalities")
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Functionalities.fetchError.get"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        const Success: Notification = {
          message: this.$tc("Views.fol-3"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        this.updateOnlyLogoDialog = false;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.fol-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async created() {
    this.loader = true;
    await this.$store
      .dispatch("functionalities/getFunctionalities")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.f-1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.loader = false;
  }

  private get functionalities(): Functionality[] {
    return this.$store.getters["functionalities/getFunctionalities"];
  }
}
