var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-book-open-variant","title":_vm.$tc('Functionalities.title'),"loading":_vm.loader}}),_c('v-row',{class:!_vm.loader ? 'full-width flex-grow-0' : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$tc('Views.f-2')}}):_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('h3',{staticClass:"ma-3"},[_vm._v(_vm._s(_vm.$tc("Functionalities.subtitle")))]),_c('v-divider',{staticClass:"mt-3"}),_c('v-row',[_c('v-col',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$tc("Functionalities.headers.functionality"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$tc("Functionalities.headers.status"))+" ")]),_c('th',{directives:[{name:"can",rawName:"v-can:update",value:([
                        _vm.$constants.PRIVILEGES.FUNCTIONALITY,
                        _vm.$constants.PRIVILEGES.KYC,
                      ]),expression:"[\n                        $constants.PRIVILEGES.FUNCTIONALITY,\n                        $constants.PRIVILEGES.KYC,\n                      ]",arg:"update"}],staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$tc("Functionalities.headers.actions"))+" ")])])]),_c('tbody',_vm._l((_vm.functionalities),function(functionality){return _c('tr',{key:functionality.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(functionality.name))]),_c('td',{staticClass:"text-center"},[(
                          (functionality.status.name ==
                            _vm.$constants.STATUS.INACTIVE &&
                            functionality.name ==
                              _vm.$constants.FUNCTIONALITY.FIRST_STEPS) ||
                          functionality.status.name ==
                            _vm.$constants.STATUS.ACTIVE
                        )?_c('v-icon',{staticClass:"mx-3",attrs:{"color":"success"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{staticClass:"mx-3",attrs:{"color":"error"}},[_vm._v("mdi-close-circle-outline")])],1),(
                        functionality.name == _vm.$constants.FUNCTIONALITY.KYC
                      )?_c('td',{directives:[{name:"can",rawName:"v-can:update",value:([
                        _vm.$constants.PRIVILEGES.FUNCTIONALITY,
                        _vm.$constants.PRIVILEGES.KYC,
                      ]),expression:"[\n                        $constants.PRIVILEGES.FUNCTIONALITY,\n                        $constants.PRIVILEGES.KYC,\n                      ]",arg:"update"}],staticClass:"text-center"},[_c('DotsMenu',{attrs:{"options":[
                          {
                            title: _vm.$tc('Action.update'),
                            icon: 'mdi-pencil',
                            event: 'update',
                            permissions: {
                              list: [_vm.$constants.PRIVILEGES.FUNCTIONALITY],
                              type: 'URL',
                            },
                          },
                        ]},on:{"update":function($event){return _vm.openUpdateDialog(functionality)}}})],1):(
                        functionality.name ==
                        _vm.$constants.FUNCTIONALITY.ONLY_LOGO
                      )?_c('td',{directives:[{name:"can",rawName:"v-can:update",value:([
                        _vm.$constants.PRIVILEGES.FUNCTIONALITY,
                        _vm.$constants.PRIVILEGES.KYC,
                      ]),expression:"[\n                        $constants.PRIVILEGES.FUNCTIONALITY,\n                        $constants.PRIVILEGES.KYC,\n                      ]",arg:"update"}],staticClass:"text-center"},[_c('DotsMenu',{attrs:{"options":[
                          {
                            title: _vm.$tc('Action.update'),
                            icon: 'mdi-pencil',
                            event: 'update',
                            permissions: {
                              list: [_vm.$constants.PRIVILEGES.FUNCTIONALITY],
                              type: 'URL',
                            },
                          },
                        ]},on:{"update":function($event){return _vm.openOnlyLogoDialog(functionality)}}})],1):_c('td',{directives:[{name:"can",rawName:"v-can:update",value:([
                        _vm.$constants.PRIVILEGES.FUNCTIONALITY,
                        _vm.$constants.PRIVILEGES.KYC,
                      ]),expression:"[\n                        $constants.PRIVILEGES.FUNCTIONALITY,\n                        $constants.PRIVILEGES.KYC,\n                      ]",arg:"update"}],staticClass:"text-center"},[_vm._v(" N/A ")])])}),0)]},proxy:true}])})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$tc("Functionalities.update")))]),_c('v-divider'),_c('v-card-text',{staticClass:"py-6"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"color":_vm.getInputColor,"inset":"","label":_vm.kycStatus
                ? _vm.$tc('Functionalities.kycLabelEnable')
                : _vm.$tc('Functionalities.kycLabelDisable')},model:{value:(_vm.kycStatus),callback:function ($$v) {_vm.kycStatus=$$v},expression:"kycStatus"}})],1),_c('p',{staticClass:"ma-0 text-justify"},[_vm._v(" "+_vm._s(_vm.$tc("Functionalities.kycMessage"))+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":function($event){_vm.updateDialog = false}}},[_vm._v(" "+_vm._s(_vm.$tc("Action.cancel"))+" ")]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.updateKYC}},[_vm._v(" "+_vm._s(_vm.$tc("Action.save"))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.updateOnlyLogoDialog),callback:function ($$v) {_vm.updateOnlyLogoDialog=$$v},expression:"updateOnlyLogoDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$tc("Views.fol-1")))]),_c('v-divider'),_c('v-card-text',{staticClass:"py-6"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"color":_vm.getInputColor,"inset":"","label":_vm.onlyLogoStatus ? _vm.$tc('Views.fol-4') : _vm.$tc('Views.fol-5')},model:{value:(_vm.onlyLogoStatus),callback:function ($$v) {_vm.onlyLogoStatus=$$v},expression:"onlyLogoStatus"}})],1),_c('p',{staticClass:"ma-0 text-justify"},[_vm._v(" "+_vm._s(_vm.$tc("Views.fol-2"))+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":function($event){_vm.updateOnlyLogoDialog = false}}},[_vm._v(" "+_vm._s(_vm.$tc("Action.cancel"))+" ")]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.updateOnlyLogo}},[_vm._v(" "+_vm._s(_vm.$tc("Action.save"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }